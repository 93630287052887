import { Routes, Route } from "react-router-dom";
import {
  Home,
  ContactUs,
  AboutUs,
  Documents,
  Finances,
  TechnicalSupport,
  Cleaning,
} from "./pages";
import { Footer, NavBar } from "./components";

function App() {
  return (
    <>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/documents" element={<Documents />} />
        <Route path="/finances" element={<Finances />} />
        <Route path="/technicalsupport" element={<TechnicalSupport />} />
        <Route path="/cleaning" element={<Cleaning />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
