import { MainContainerServices } from "../components/ServicesPageStyles/ServicesComponents";
import finImg from "../data/images/Finances-home.jpg";
import { FaPagelines } from "react-icons/fa";

const Finances = () => {
  return (
    <MainContainerServices>
      <h1>ФИНАНСИ</h1>
      <figure>
        <img src={finImg} alt="Финанси" width="640" height="427" />
      </figure>
      <h1>
        <FaPagelines /> Какво предлагаме:
      </h1>
      <p>
        - Събиране на месечните вноски, чрез посещение на адрес от касиер два
        пъти месечно и заплащане на всички раходи на етажната собственост;
      </p>
      <p>- Възможност за заплащане на месечните такси чрез банков превод;</p>
      <p>
        - Периодична отчетност за наличността в касата на етажната собственост;
      </p>
      <p>
        - Всеки от вас има възможност да заплати дължимите такси и по банков
        път:
      </p>
      <h1>IBAN: BG55 BPBI 7945 1071 3364 01</h1>
      <h1>BIC – BPBIBGSF</h1>
      <h1>ЮРОБАНК БЪЛГАРИЯ АД</h1>
      <h1>Титуляр:</h1>
      <h1>Хоум Център България ЕООД</h1>
      <p>
        <u>
          'Необходимо е в основание за плащане да посочите : адреса на сградата
          и номера на апартамента'
        </u>
      </p>
    </MainContainerServices>
  );
};

export default Finances;
