import React from "react";
import { MainContainerContactUs } from "../components/ContactUsPageStyles/ContactUsComponents";
import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const ContactUs = () => {
  return (
    <MainContainerContactUs>
      <h1>"ХОУМ ЦЕНТЪР БЪЛГАРИЯ" ЕООД</h1>
      <h1>Офис гр. Варна:</h1>
      <h1>Работно време:</h1>
      <p>понеделник - петък</p>
      <p>
        от 9:00 до 17:00 часа <u>за предложения и сигнали</u>
      </p>
      <h1>Моб.тел.:</h1>
      <p>0877 557 442</p>
      <h1> e-mail:</h1>
      <p>office@homecenterbg.eu</p>
      <h1>Последвайте ни:</h1>
      <a href="http://facebook.com">
        <FaFacebook />
      </a>
      <a href="http://instagram.com">
        <FaInstagram />
      </a>
      <a href="http://twitter.com">
        <FaTwitter />
      </a>
    </MainContainerContactUs>
  );
};

export default ContactUs;
