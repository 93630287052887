import { NavLink as Link } from "react-router-dom"
import { FaBars, FaHome } from "react-icons/fa"
import styled from "styled-components"


export const Nav = styled.nav`
    background: #3b3c42;
    height: ${(props) => (props.extendNavBar ? '100vh' : '80px')};
    display: block;
    z-index: 10;

    @media (min-width: 700px) {
        height: 80px;
    }
`

export const InnerContainer = styled.div`
    display: flex;
    width: 100%;
    height: 80px;
`

export const ExtendedContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 700px) {
        display: none;
    }
`

export const LeftNavBar = styled.div`
    display: flex;
    flex: 15%;
    justify-content: flex-start;
    align-items: center;
`

export const LogoImg = styled.img`
    margin: 10px;
    max-width: 180px;
    height: auto;
`

export const MiddleNavBar = styled.div`
    display: flex;
    flex: 70%;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 700px) {
        display: none;
    }
`

export const RightNavBar = styled.div`
    display: flex;
    flex: 15%;
    justify-content: flex-start;
    align-items: center;
`

export const NavLink = styled(Link)`
color: #fff;
display: flex;
align-items: center;
text-decoration: none;
padding: 0 1rem;
height: 100%;
cursor: pointer;

:hover {
        color: #ec2712;
    }

&.active {
  color: #ec2712;
}
`

export const NavLinkExtended = styled(Link)`
color: #fff;
display: flex;
align-items: center;
text-decoration: none;
font-size: x-large;
margin: 10px;
height: 100%;
cursor: pointer;

:hover {
        color: #ec2712;
    }

&.active {
  color: #ec2712;
}
`

export const Bars = styled(FaBars)`
display: none;
color: #fff;

@media screen and (max-width: 700px) {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-100%, 75%);
  font-size: 1.8rem;
  cursor: pointer;
}
`

export const HomeLink = styled(FaHome)`

`

export const NavMenuExtended = styled.div`
    display: block;
    justify-content: center;
    align-items: center;
`

export const DropDown = styled.div`
    position: absolute;
    top: 55px;
    left: -100px;
    background: #3b3c42;
    opacity: 0.9;
    padding: 0;
    margin: 0;
    width: 320px;
    -webkit-box-shadow: 0px 0px 14px rgb(0 0 0 / 20%);
    box-shadow: 0px 0px 14px rgb(0 0 0 / 20%);
    z-index: 999;
    border: 1px solid rgba(255,255,255,.2);
    font-size: .9em;
    padding: 10px 15px;
`

export const NavLinkDropDown = styled.div`
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0 1rem;
    height: 100%;
    cursor: pointer;

    :hover {
        color: #ec2712;
    }

    &.active {
      color: #ec2712;
    }
`

export const DropDownExtended = styled.div`
    position: absolute;
    top: 28px;
    left: -100px;
    background: #3b3c42;
    opacity: 0.9;
    padding: 0;
    margin: 0;
    width: 320px;
    -webkit-box-shadow: 0px 0px 14px rgb(0 0 0 / 20%);
    box-shadow: 0px 0px 14px rgb(0 0 0 / 20%);
    z-index: 999;
    border: 1px solid rgba(255,255,255,.2);
    font-size: .9em;
    padding: 10px 15px;
`

export const NavLinkDropDownExtended = styled.div`
    position: relative;
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: x-large;
    margin: 10px;
    height: 100%;
    cursor: pointer;

    :hover {
        color: #ec2712;
    }

    &.active {
      color: #ec2712;
    }
`

export const NavLinkDropDownLinks = styled(Link)`
color: #fff;
display: flex;
align-items: center;
text-decoration: none;
padding: 0.8rem 1rem;
height: 100%;
border-bottom: 1px solid rgba(255,255,255,.2);
cursor: pointer;

:hover {
        color: #ec2712;
    }

&.active {
  color: #ec2712;
}
`