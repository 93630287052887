import styled from "styled-components";

export const MainContainer = styled.main`
  display: block;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: auto;
  z-index: 1;
  margin: 0 auto;
  padding: 30px;

  .header__h1 {
    color: #920700;
    margin-top: 20px;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
  }
`;

export const GridImagesLinks = styled.main`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  justify-items: center;

  figure {
    width: 40%;
    height: auto;
    margin-top: 80px;
    padding: 1px 1px 30px;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.7);
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    min-width: 100px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }

  figcaption {
    display: grid;
    place-content: center;
    text-align: center;
    font-size: 1.8rem;
    color: #920700;
    margin-top: 10px;
  }
`;

export const Banner = styled.main`
  display: block;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: auto;
  z-index: 1;
  margin: 100px auto;
  padding: 30px;

  .banner__h2 {
    color: #920700;
    margin-top: 20px;
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
    text-transform: uppercase;
  }

  article {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.4rem;
  }

  figure {
    display: flex;
    justify-content: center;
    min-width: 200px;
    max-width: 200px;
    min-height: 200px;
    max-height: 200px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 50%;
  }
`;
