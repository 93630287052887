import styled from "styled-components";

export const MainContainerAboutUs = styled.main`
  display: block;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: auto;
  z-index: 1;
  margin: 100px auto;
  padding: 30px;

  h1 {
    margin-top: 10px;
    font-size: 1.8rem;
    font-weight: 800;
    margin-bottom: 15px;
    color: #920700;
    text-align: center;
  }

  p {
    margin-bottom: 10px;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #3f3f3f;
    font-weight: bold;
  }
`;
