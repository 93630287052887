import React from "react";
import { MainContainerAboutUs } from "../components/AboutUsPageStyles/AboutUsComponents";

const AboutUs = () => {
  return (
    <MainContainerAboutUs>
      <h1>За НАС</h1>
      <p>
        Основана през 2016 година в гр. Варна, Хоум Център България започва
        своето съществуване със закупуването на жилище в новопостроена сграда в
        един от кварталите на града. Желанието и стремежът за придаване на
        приятна и дружелюбна среда за живеене, прераства в идеята за управление
        и стопанисване на сградата като професионален домоуправител. Неспирно
        водени от същите интереси за внимание, грижа и лична отговорност към
        всяко жилище като за свой собствен дом, след 5 изминали години, адресите
        на домовете ни наброяват повече от 100, а признателните домакинства са
        над 3000. Нашата най-добра и достатъчна реклама е доверието,
        удовлетвореността и благодарностите за добре свършената работа.
      </p>
      <p>Благодарим Ви!</p>
      <p>Екипът на Хоум Център България ЕООД</p>
    </MainContainerAboutUs>
  );
};

export default AboutUs;
