import styled from "styled-components";

export const FooterContainer = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  height: auto;
  z-index: 1;
  margin: 0 auto;
  padding: 30px;
  color: #920700;

  a,
  a:visited {
    color: #920700;
    margin-left: 2px;
  }
`;
