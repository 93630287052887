import React, { useState } from 'react'
import { 
  Nav,
  LeftNavBar,
  LogoImg,
  MiddleNavBar,
  RightNavBar,
  Bars,
  NavLink,
  NavLinkExtended,
  HomeLink,
  InnerContainer,
  ExtendedContainer,
  NavLinkDropDown,
  DropDown,
  DropDownExtended,
  NavLinkDropDownExtended,
  NavLinkDropDownLinks
} from './NavBarComponents'
import Logo from '../data/images/hcb-logo.svg'
import { FaChevronDown } from 'react-icons/fa'
import { BsPhone } from 'react-icons/bs'

const NavBar = () => {
  const [extendNavBar, setExtendNavBar] = useState(false)
  const [test, setTest] = useState(false)

  return (
    <Nav extendNavBar={extendNavBar}>
      <InnerContainer>
      <Bars onClick={() => {setExtendNavBar((curr) => !curr)}}/>
        <LeftNavBar>
          <NavLink to='/'>
            <LogoImg src={Logo} alt='logo' />
          </NavLink>
        </LeftNavBar>
        <MiddleNavBar>
          <NavLink to='/'><HomeLink /></NavLink>
          <NavLink to='/aboutus'>За Нас</NavLink>
          <NavLinkDropDown
            onMouseOver={() => {setTest(true)}}
            onMouseOut={() => {setTest(false)}}
          >
            Услуги &nbsp;
            <FaChevronDown />
            {test && (
              <DropDown>
                  <NavLinkDropDownLinks to='/documents' >Документи</NavLinkDropDownLinks>
                  <NavLinkDropDownLinks to='/finances' >Финанси</NavLinkDropDownLinks>
                  <NavLinkDropDownLinks to='/technicalsupport' >Техническа поддръжка</NavLinkDropDownLinks>
                  <NavLinkDropDownLinks to='/cleaning' >Почистване</NavLinkDropDownLinks>
              </DropDown>
            )}
          </NavLinkDropDown>
          <NavLink to='/contactus'>Контакти</NavLink>
        </MiddleNavBar>
        <RightNavBar>
          <NavLink to='/contactus'> <BsPhone />0877 557 442</NavLink>
        </RightNavBar>
      </InnerContainer>
      {extendNavBar && (<ExtendedContainer>
      <NavLinkExtended to='/' onClick={() => {setExtendNavBar(false)}}><HomeLink /></NavLinkExtended>
          <NavLinkExtended to='/aboutus' onClick={() => {setExtendNavBar(false)}}>За Нас</NavLinkExtended>
          <NavLinkDropDownExtended
            onMouseOver={() => {setTest(true)}}
            onMouseOut={() => {setTest(false)}}
          >
            Услуги &nbsp;
            <FaChevronDown />
            {test && (
              <DropDownExtended>
                  <NavLinkDropDownLinks to='/documents' onClick={() => {setExtendNavBar(false)}} >Документи</NavLinkDropDownLinks>
                  <NavLinkDropDownLinks to='/finances' onClick={() => {setExtendNavBar(false)}} >Финанси</NavLinkDropDownLinks>
                  <NavLinkDropDownLinks to='/technicalsupport' onClick={() => {setExtendNavBar(false)}} >Техническа поддръжка</NavLinkDropDownLinks>
                  <NavLinkDropDownLinks to='/cleaning' onClick={() => {setExtendNavBar(false)}} >Почистване</NavLinkDropDownLinks>
              </DropDownExtended>
            )}
          </NavLinkDropDownExtended>
          <NavLinkExtended to='/contactus' onClick={() => {setExtendNavBar(false)}}>Контакти</NavLinkExtended>
      </ExtendedContainer>)}
    </Nav>
    
  )
}

export default NavBar
