import { MainContainerServices } from "../components/ServicesPageStyles/ServicesComponents";
import { FaPagelines } from "react-icons/fa";
import techImg from "../data/images/Technical-home.jpg";

const TechnicalSupport = () => {
  return (
    <MainContainerServices>
      <h1>ТЕХНИЧЕСКА ПОДДРЪЖКА</h1>
      <figure>
        <img
          src={techImg}
          alt="Техническа поддръжка"
          width="640"
          height="427"
        />
      </figure>
      <h1>
        <FaPagelines /> Какво предлагаме:
      </h1>
      <p>
        - Техническа поддръжка на входа / смяна на ел. ключове, ел.крушки,
        регулиране затварящ апарат на входната врата и др.,{" "}
        <u>като ЕС заплаща само стойността на вложените материали;</u>
      </p>
      <p>- Ежемесечен отчет за извършените разходи и ремонти в сградата;</p>
      <p>- Представяне на оферти за извършване на текущи ремонти;</p>
      <p>- Отстраняване на повреди във възможно най-кратки срокове;</p>
      <p>- Проверка и оглед на техническото състояние два пъти месечно;</p>
    </MainContainerServices>
  );
};

export default TechnicalSupport;
