import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-responsive-carousel/lib/styles/carousel.css";
import {
  GridImagesLinks,
  MainContainer,
  Banner,
} from "../components/HomePageStyles/HomePageComponents";
import imageDocumentHome from "../data/images/Documents-home.jpg";
import imageFinanceHome from "../data/images/Finances-home.jpg";
import imageTechnicalSupportHome from "../data/images/Technical-home.jpg";
import imageCleaningHome from "../data/images/Cleaning-home.jpg";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import avImg1 from "../data/images/avComment1.svg";
import avImg2 from "../data/images/avComment2.svg";

const Home = () => {
  return (
    <>
      <MainContainer>
        <h1 className="header__h1">
          <strong>ХОУМ ЦЕНТЪР БЪЛГАРИЯ</strong>
          <p>ПРОФЕСИОНАЛЕН ДОМОУПРАВИТЕЛ</p>
        </h1>
      </MainContainer>
      <GridImagesLinks>
        <figure>
          <Link to="/documents">
            <img
              src={imageDocumentHome}
              alt="documents"
              title="Документи"
              width="640"
              height="427"
            />
          </Link>
          <figcaption>Документи</figcaption>
        </figure>
        <figure>
          <Link to="/finances">
            <img
              src={imageFinanceHome}
              alt="Финанси"
              title="Финанси"
              width="640"
              height="427"
            />
          </Link>
          <figcaption>Финанси</figcaption>
        </figure>
      </GridImagesLinks>
      <GridImagesLinks>
        <figure>
          <Link to="/technicalsupport">
            <img
              src={imageTechnicalSupportHome}
              alt="Техническа поддръжка"
              title="Техническа поддръжка"
              width="640"
              height="427"
            />
          </Link>
          <figcaption>Техническа поддръжка</figcaption>
        </figure>
        <figure>
          <Link to="/cleaning">
            <img
              src={imageCleaningHome}
              alt="Почистване"
              title="Почистване"
              width="640"
              height="427"
            />
          </Link>
          <figcaption>Почистване</figcaption>
        </figure>
      </GridImagesLinks>
      <Banner>
        <h2 className="banner__h2">
          <p>ОТЗИВИ ОТ КЛИЕНТИ</p>
        </h2>
        <Carousel
          showArrows={false}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          showThumbs={false}
          stopOnHover={true}
          swipeable={true}
        >
          <article>
            <figure>
              <img
                src={avImg1}
                alt="Моите впечатления от работата, че сте отлични професионалисти,
              много отзивчиви, проявяващи винаги необходимото разбиране и
              търпение към клиентите."
              />
            </figure>
            <p>
              "Моите впечатления от работата, че сте отлични професионалисти,
              много отзивчиви, проявяващи винаги необходимото разбиране и
              търпение към клиентите."
            </p>
          </article>
          <article>
            <figure>
              <img
                src={avImg2}
                alt="Професията ми  е свързана с много стрес и изтощителни пътувания. При този натоварен график ми беше трудно да намеря фирма на която да мога спокойно да се доверя за ремонт. Имах нужда точно от вас за да ми помогнете."
              />
            </figure>
            <p>
              "Професията ми е свързана с много стрес и изтощителни пътувания.
              При този натоварен график ми беше трудно да намеря фирма на която
              да мога спокойно да се доверя за ремонт. Имах нужда точно от вас
              за да ми помогнете."
            </p>
          </article>
          <article>
            <figure>
              <img
                src={avImg2}
                alt="Бих казал, че това е една от най-професионалните фирми във Варна, която предлага услугата домоуправител, както и различните допълнителни услуги. Наистина съм много доволен."
              />
            </figure>
            <p>
              "Бих казал, че това е една от най-професионалните фирми във Варна,
              която предлага услугата домоуправител, както и различните
              допълнителни услуги. Наистина съм много доволен."
            </p>
          </article>
          <article>
            <figure>
              <img
                src={avImg1}
                alt="Касиерът минава винаги и издава квитанция! И дават ежемесечни отчети!"
              />
            </figure>
            <p>
              "Касиерът минава винаги и издава квитанция! И дават ежемесечни
              отчети!"
            </p>
          </article>
          <article>
            <figure>
              <img
                src={avImg2}
                alt="Нашият блок сме изключително доволни от професионалното отношение и обслужване. Препоръчваме тази фирма горещо на всички."
              />
            </figure>
            <p>
              "Нашият блок сме изключително доволни от професионалното отношение
              и обслужване. Препоръчваме тази фирма горещо на всички."
            </p>
          </article>
        </Carousel>
      </Banner>
    </>
  );
};

export default Home;
