import docImg from "../data/images/Documents-home.jpg";
import { FaPagelines } from "react-icons/fa";
import { MainContainerServices } from "../components/ServicesPageStyles/ServicesComponents";

const Documents = () => {
  return (
    <MainContainerServices>
      <h1>ДОКУМЕНТИ</h1>
      <figure>
        <img src={docImg} alt="Документи" width="640" height="427" />
      </figure>
      <h1>
        <FaPagelines /> Какво предлагаме:
      </h1>
      <p>- Провеждане и протоколиране на общи събрания съгласно ЗУЕС;</p>
      <p>- Водене на необходимата документация, съгласно ЗУЕС;</p>
    </MainContainerServices>
  );
};

export default Documents;
