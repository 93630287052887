import { FooterContainer } from "./FooterPageStyles/FooterComponents";

const Footer = () => {
  return (
    <FooterContainer>
      Designed by <a href="http://www.aikidevs.com">AikiDevs</a>
    </FooterContainer>
  );
};

export default Footer;
