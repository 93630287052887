import styled from "styled-components";

/* export const MainContainerContactUs = styled.div`
    position: absolute;
    width: 100vw;
    height: auto;
    z-index: 1;
    left: 0;
    transform: translateZ(0);
    will-change: transform;
` */

export const MainContainerContactUs = styled.main`
  display: block;
  background: rgba(255, 255, 255, 0.7);
  --background: transparent;
  width: 100%;
  height: auto;
  z-index: 1;
  margin: 100px auto;
  padding: 30px;
  color: #920700;

  h1 {
    margin-top: 10px;
    font-size: 1.8rem;
    font-weight: 800;
    margin-bottom: 15px;
    text-align: left;
  }

  p {
    font-size: 1.2rem;
    color: #3f3f3f;
    font-weight: bold;
  }

  a {
    text-decoration: none;
    font-size: x-large;
    margin-right: 10px;
    color: blue;
  }
`;
