import { MainContainerServices } from "../components/ServicesPageStyles/ServicesComponents";
import cleImg from "../data/images/Cleaning-home.jpg";
import { FaPagelines } from "react-icons/fa";

const Cleaning = () => {
  return (
    <MainContainerServices>
      <h1>ПОЧИСТВАНЕ</h1>
      <figure>
        <img src={cleImg} alt="Почистване" width="640" height="427" />
      </figure>
      <h1>
        <FaPagelines /> Какво предлагаме:
      </h1>
      <p>
        - При желание на ЕС, "ХОУМ ЦЕНТЪР БЪЛГАРИЯ" ЕООД, ще предостави
        индивидуална оферта за почистване на общите части, изцяло съобразена с
        изискванията на собствениците.
      </p>
    </MainContainerServices>
  );
};

export default Cleaning;
